<template>
  <AppBaseColumn gap="xsmall" color="bg-surface-base">
    <span class="l2 text-left">Zamana göre filtreleyin</span>
    <AppBaseRow gap="xsmall" color="bg-surface-base">
      <ReportsFilterChip
        v-for="option in rangeOptions"
        :key="option.filterRange"
        :label="option.label"
        :selected="selectedRange === option.filterRange"
        :onClick="() => changeRange(option.filterRange)"
      />
    </AppBaseRow>
  </AppBaseColumn>
</template>
<script setup lang="ts">
import { useStatusStore, type FilterRange } from "~/store/status";

const statusStore = useStatusStore();
const selectedRange = computed(() => statusStore.filter.range);

const changeRange = (range: FilterRange) => {
  statusStore.updateFilterRange(range);
};

const rangeOptions: {
  filterRange: FilterRange;
  label: string;
}[] = [
  {
    filterRange: "last3Months",
    label: "Son 3 ay",
  },
  {
    filterRange: "last6Months",
    label: "Son 6 ay",
  },
  {
    filterRange: "lastYear",
    label: "Son 1 yıl",
  },
];
</script>
