<template>
  <ChartBox
    id="newcomingUsersContainer"
    title="Aramıza yeni katılanlar"
    subtitle="İlgili ay içerisinde yeni üye olan ve ilk görüşmesini yapan üye sayıları."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="
        loading || loading2 || isUsersWithFirstMeetingsEmpty || isNewUsersEmpty
      "
      :isEmpty="isUsersWithFirstMeetingsEmpty || isNewUsersEmpty"
      :loading="loading || loading2"
    />
    <ChartColumn
      v-else
      chartId="newcomingUsers"
      :data="
        generateMultiColumnChartData(
          newUsers,
          'Yeni üye olanlar',
          usersWithFirstMeetings,
          'İlk görüşmesini yapanlar'
        )
      "
      yAxisTitle="Kişi sayısı"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";

const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const newUsers = computed(() => statusStore.newUsers);
const usersWithFirstMeetings = computed(
  () => statusStore.usersWithFirstMeetings
);
const isNewUsersEmpty = computed(
  () => !newUsers.value || newUsers.value.length === 0
);
const isUsersWithFirstMeetingsEmpty = computed(
  () =>
    !usersWithFirstMeetings.value || usersWithFirstMeetings.value.length === 0
);

const [loading, runWithLoading] = useLoading();
const [loading2, runWithLoading2] = useLoading();
const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadNewUsers(filter.value.sponsorId, filter.value.range)
  );

  await runWithLoading2(() =>
    statusStore.loadUsersWithFirstMeetings(
      filter.value.sponsorId,
      filter.value.range
    )
  );
};

onBeforeMount(fetchData);

watch(
  () => [filter.value.sponsorId, filter.value.range],
  async ([newSponsorId, newRange], [oldSponsorId, oldRange]) => {
    if (newSponsorId !== oldSponsorId || newRange !== oldRange) {
      await fetchData();
    }
  }
);
</script>
