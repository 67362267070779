<template>
  <div
    class="bg-surface-10 border border-solid px-3 py-4 rounded-xl cursor-pointer outline-none select-none flex items-center"
    :class="{
      'border-primaryV2': isSelected,
      'border-surface-60': !isSelected,
    }"
    @click="onRadioClick"
  >
    <input
      type="radio"
      :name="option"
      :value="option"
      class="mr-3 accent-primaryV2 w-5 h-5 pointer-events-none cursor-pointer"
      :checked="isSelected"
    />
    <label class="b2 cursor-pointer" :class="{ 'text-primaryV2': isSelected }">
      {{ option }}
    </label>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  option: String,
  isSelected: Boolean,
  onSelect: Function,
});

const { onSelect, option } = props;

const onRadioClick = () => {
  onSelect([option]);
};
</script>
