<template>
  <ChartBox
    id="cumulativeMeetingsContainer"
    title="Kümülatif seans sayısı"
    subtitle="İlgili tarihe kadar yapılan toplam seans sayısı."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isCumulativeMeetingsEmpty"
      :isEmpty="isCumulativeMeetingsEmpty"
      :loading="loading"
    />
    <ChartColumn
      v-else
      chartId="cumulativeMeetings"
      :data="generateColumnChartData(cumulativeMeetings, 'Seans')"
      yAxisTitle="Seans sayısı"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";
const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const cumulativeMeetings = computed(() => statusStore.cumulativeMeetings);
const isCumulativeMeetingsEmpty = computed(
  () => !cumulativeMeetings.value || cumulativeMeetings.value.length === 0
);
const [loading, runWithLoading] = useLoading();

const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadCumulativeMeetings(
      filter.value.sponsorId,
      filter.value.range
    )
  );
};

onBeforeMount(fetchData);

watch(
  () => [filter.value.sponsorId, filter.value.range],
  async ([newSponsorId, newRange], [oldSponsorId, oldRange]) => {
    if (newSponsorId !== oldSponsorId || newRange !== oldRange) {
      await fetchData();
    }
  }
);
</script>
