<template>
  <ChartBase
    :chartId="chartId"
    :chartOptions="lineOptions"
    :initialWidth="200"
    :initialHeight="260"
  />
</template>
<script setup lang="ts">
export type LineChartData = {
  category: string;
  series: {
    name: string;
    value: number;
  }[];
};

const props = defineProps({
  chartId: {
    type: String,
    required: true,
  },
  data: {
    type: Array as PropType<LineChartData[]>,
    required: true,
  },
  yAxisTitle: {
    type: String,
    required: false,
  },
  staticLineValue: {
    type: Number,
    required: false,
    default: 0,
  },
  staticLineCategory: {
    type: String,
    required: false,
  },
});

const { chartId, data, yAxisTitle, staticLineCategory, staticLineValue } =
  props;

const nuxtApp = useNuxtApp();
const highchartStyles = nuxtApp.$highchartStyles;
const { fonts, colors } = highchartStyles ?? {};

const globalColors = nuxtApp.$highcharts?.getOptions()?.colors;

const categories = data.map((item) => item.category);
const seriesNames = data[0].series.map((item) => item.name);
const seriesData = seriesNames.map((name, index) => ({
  name,
  data: data.map((item) => {
    const seriesItem = item.series.find((s) => s.name === name);
    return seriesItem ? seriesItem.value : 0;
  }),
  color: globalColors?.[index],
  marker: {
    enabled: true,
  },
}));

if (staticLineCategory) {
  const staticLineSeries = {
    name: staticLineCategory,
    data: data.map(() => staticLineValue),
    dashStyle: "3,2",
    color: colors?.primary.base,
    dataLabels: {
      enabled: false,
    },
    marker: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  seriesData.push(staticLineSeries);
}

const lineOptions = ref({
  chart: {
    type: "line",
    backgroundColor: colors?.surface[10],
  },
  title: {
    text: null,
  },
  xAxis: {
    categories: categories,
    labels: {
      style: fonts?.l3,
      rotation: categories.length > 5 ? -45 : 0,
      step: 1,
      staggerLines: 1,
    },
  },
  yAxis: {
    min: 0,
    labels: {
      style: fonts?.l3,
    },
    title: {
      text: yAxisTitle,
      style: fonts?.l3,
    },
    tickAmount: 6,
  },
  legend: {
    align: "center",
    verticalAlign: "bottom",
    layout: "horizontal",
    itemStyle: fonts?.l3,
    width: "100%",
    itemWidth: null,
  },
  plotOptions: {
    line: {
      connectNulls: true,
      dataLabels: {
        enabled: true,
        crop: false,
        overflow: "none",
        formatter: function (this: any) {
          return new Intl.NumberFormat("tr-TR").format(this.point.y);
        },
      },
    },
  },
  tooltip: {
    useHTML: true,
    style: {
      pointerEvents: "auto",
      zIndex: 10000,
    },
    formatter: function (this: any) {
      if (this.series.name === staticLineCategory) {
        return `
          <div class="flex items-center gap-1">
            <span style="border-color: ${this.series.color};" class="inline-block w-3 h-0.5 border-t border-dashed"></span>
            <span>${this.series.name}: ${this.y}</span>
          </div>`;
      }

      const currentPoint = this.point;
      const previousPoint = this.series.data[this.point.index - 1];
      let comparisonText = "";
      let iconComponent = "";
      if (
        previousPoint &&
        previousPoint.y !== 0 &&
        currentPoint.y !== 0 &&
        previousPoint.y !== null
      ) {
        const percentageChange =
          ((currentPoint.y - previousPoint.y) / previousPoint.y) * 100;

        let changeType = "nochange";

        if (percentageChange > 0) {
          changeType = "increase";
        } else if (percentageChange < 0) {
          changeType = "decrease";
        }

        const colors = {
          increase: "text-system-success-base",
          decrease: "text-system-alert-base",
          nochange: "text-system-warning-60",
        };

        const icons = {
          increase: "icon-increase",
          decrease: "icon-decrease",
          nochange: "icon-notchaged",
        };

        const comparisonTexts = {
          increase: `%${parseFloat(
            Math.abs(percentageChange).toFixed(2)
          )} artış`,
          decrease: `%${parseFloat(
            Math.abs(percentageChange).toFixed(2)
          )} düşüş`,
          nochange: "Değişim yok",
        };

        comparisonText = `<span class="${colors[changeType]}">${comparisonTexts[changeType]}</span>`;

        iconComponent = `<span class="w-[14px] h-[14px] ${colors[changeType]} ${icons[changeType]} "></span>`;
      }

      return `
        <div class="grid gap-3 b3 bg-surface-10">
          <div class="grid">
            <span class="text-neutral-40">${currentPoint.category}</span>
            <div class="flex items-center gap-1">
            <span style="background-color: ${
              this.series.color
            };" class="inline-block w-[6px] h-[6px] rounded-full "></span>
            <span>${currentPoint.series.name}: ${currentPoint.y} 
            </span>
            </div>
          </div>
          ${
            comparisonText
              ? `
                <div class="grid">
                  <span class="text-neutral-40">Geçen aya göre</span>
                  <div class="flex gap-1 items-center">
                    ${iconComponent}
                    ${comparisonText}       
                  </div>
                </div>
                `
              : ""
          }
        </div>
      `;
    },
  },
  series: seriesData,
});
</script>
