<template>
  <div :id="chartId" class="chart-container">
    <highcharts :options="options" ref="chart" />
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  chartId: {
    type: String,
    required: true,
  },
  chartOptions: {
    type: Object,
    required: true,
  },
  initialWidth: {
    type: Number,
    required: false,
    default: 400,
  },
  initialHeight: {
    type: Number,
    required: false,
    default: 300,
  },
  autoResize: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const { initialWidth, initialHeight, chartId, chartOptions, autoResize } =
  props;

const chartRef = ref(null);

const chartWidth = ref(initialWidth);
const chartHeight = ref(initialHeight);

const updateChartDimensions = () => {
  const container = document.querySelector(`#${chartId}`);
  if (container && autoResize) {
    chartWidth.value = container.clientWidth;
    chartHeight.value = container.clientHeight;

    if (chartRef.value) {
      chartRef.value.reflow();
    }
  }
};

onMounted(() => {
  nextTick(() => {
    updateChartDimensions();
  });
  window.addEventListener("resize", updateChartDimensions);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateChartDimensions);
});

const options = ref({
  ...chartOptions,
  chart: {
    ...chartOptions.chart,
    height: chartHeight,
    width: chartWidth,
    events: {
      load: function () {
        chartRef.value = this;
        nextTick(() => {
          chartRef.value.reflow();
        });
      },
    },
  },
  credits: {
    enabled: false, // Disable highcharts.com credits
  },
});
</script>

<style scoped>
.chart-container {
  @apply h-full w-full flex justify-center items-center z-0;
}

.loading-box {
  @apply bg-neutral-base rounded-lg p-8 flex flex-col items-center select-none;
}

.loading-spinner {
  @apply border-4 border-solid border-[#FFFFFF55] border-t-[#FFFFFFFF] rounded-full w-12 h-12 animate-spin;
}
</style>
