<template>
  <ChartBox
    id="demographyGenderContainer"
    title="Cinsiyet dağılımı"
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isGenderEmpty"
      :isEmpty="isGenderEmpty"
      :loading="loading"
    />
    <ChartDonut
      v-else
      chartId="demographyGender"
      :data="generateDonutChartData(userDemography.gender, 'Cinsiyet')"
      :isDense="true"
    />
  </ChartBox>
  <ChartBox
    id="demographyAgeContainer"
    title="Yaş dağılımı"
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isAgeEmpty"
      :isEmpty="isAgeEmpty"
      :loading="loading"
    />
    <ChartDonut
      v-else
      chartId="demographyAge"
      :data="generateDonutChartData(userDemography.age, 'Yaş')"
    />
  </ChartBox>
  <ChartBox
    id="demographyParenthoodContainer"
    title="Ebeveynlik durumu"
    :bottomVisible="false"
    :hasPadding="false"
  >
    <ChartLoading
      v-if="loading || isParenthoodEmpty"
      :isEmpty="isParenthoodEmpty"
      :loading="loading"
    />
    <ChartDonut
      v-else
      chartId="demographyParenthood"
      :data="
        generateDonutChartData(userDemography.parenthood, 'Ebeveynlik Durumu')
      "
      :isDense="true"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";

const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const userDemography = computed(() => statusStore.userDemography);
const isGenderEmpty = computed(
  () =>
    !userDemography.value ||
    Object.keys(userDemography.value?.gender).length === 0
);
const isAgeEmpty = computed(
  () =>
    !userDemography.value || Object.keys(userDemography.value?.age).length === 0
);
const isParenthoodEmpty = computed(
  () =>
    !userDemography.value ||
    Object.keys(userDemography.value?.parenthood).length === 0
);

const [loading, runWithLoading] = useLoading();

const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadUserDemography(filter.value.sponsorId)
  );
};

onBeforeMount(fetchData);

watch(
  () => [filter.value.sponsorId],
  async ([newSponsorId], [oldSponsorId]) => {
    if (newSponsorId !== oldSponsorId) {
      await fetchData();
    }
  }
);
</script>
