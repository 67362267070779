<template>
  <ChartBase
    :chartId="chartId"
    :chartOptions="donutOptions2"
    :initialWidth="200"
    :initialHeight="160"
  />
</template>
<script setup lang="ts">
export type DonutChartData = {
  category: string;
  series: {
    name: string;
    value: number;
  }[];
};

const props = defineProps({
  chartId: {
    type: String,
    required: true,
  },
  data: {
    type: Object as PropType<DonutChartData>,
    required: true,
  },
  staticSize: {
    type: Number,
    required: false,
    default: 200,
  },
  isDense: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { data, chartId, staticSize, isDense } = props;
const nuxtApp = useNuxtApp();
const highchartStyles = nuxtApp.$highchartStyles;
const { fonts, colors } = highchartStyles ?? {};

const globalColors = nuxtApp.$highcharts?.getOptions()?.colors;

const donutOptions2 = ref({
  chart: {
    type: "pie",
    spacing: [5, 5, 5, 5],
    backgroundColor: colors?.surface[10],
  },
  title: {
    text: null,
  },
  plotOptions: {
    pie: {
      size: staticSize,
      innerSize: "60%",
      borderWidth: 0,
      borderRadius: 0,
    },
    series: {
      dataLabels: [
        {
          enabled: true,
          distance: isDense ? 5 : 10,
          format: isDense
            ? '<div class="bg-primary-main l3 text-neutral-base break-normal whitespace-normal">{point.name}</div>'
            : '<div class="bg-primary-main l3">{point.name}</div>',
          allowOverlap: true,
          useHTML: true,
        },
        {
          enabled: true,
          distance: -20,
          format: "%{point.percentage:.0f}",
          allowOverlap: false,
          style: {
            ...fonts?.l3,
            color: colors?.surface[10],
          },
        },
      ],
    },
  },
  series: [
    {
      innerSize: "65%",
      name: data.category,
      colorByPoint: true,
      data: data.series.map((item, index) => ({
        name: item.name,
        y: item.value,
        color: globalColors?.[index],
      })),
    },
  ],
});
</script>
