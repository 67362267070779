<template>
  <ModalSlideSheet
    title="Şirketi değiştirin"
    :open="open"
    :onClose="onClose"
    :onBack="onClose"
  >
    <template #content>
      <AppBaseColumn gap="small" class="w-full">
        <span class="l1 text-start">Şirket seçin</span>
        <OptionItemSingle
          v-for="sponsor in availableSponsors"
          :key="sponsor.id"
          :option="`${sponsor.name} - ${sponsor.identifier}`"
          :isSelected="sponsor.id === selectedId"
          :onSelect="() => onSelect(sponsor.id)"
        />
      </AppBaseColumn>
    </template>
    <template #footer-secondary-button>
      <ButtonSecondaryMedium label="Vazgeçin" @click="() => onClose()" />
    </template>
    <template #footer-primary-button>
      <ButtonPrimaryMedium
        label="Güncelleyin"
        :disabled="selectedId === initialId"
        @click="onConfirm"
      />
    </template>
  </ModalSlideSheet>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";
import { useUserStore } from "~/store/user";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
});

const userStore = useUserStore();
const statusStore = useStatusStore();

const availableSponsors = computed(() => userStore.insightsProfile.sponsors);
const initialId = computed(() => statusStore.filter.sponsorId);

const selectedId = ref(initialId.value);
const onSelect = (id: string) => {
  selectedId.value = id;
};

const onConfirm = () => {
  statusStore.updateFilterSponsorId(selectedId.value);
  props.onClose();
};
</script>
