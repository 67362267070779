<template>
  <div
    :class="{
      'h-6 px-3 rounded-[32px] items-center gap-2 inline-flex w-min': true,
      [backgroundColor]: true,
      [borderColor]: borderColor,
      ['border']: borderColor,
      [chipClass]: chipClass,
    }"
  >
    <span
      v-if="icon != null"
      :class="{
        'text-sm': true,
        [icon]: true,
      }"
    />
    <div
      :class="{
        'l3 text-center whitespace-nowrap': true,
        [textColor]: true,
      }"
    >
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts">
export default {
  setup() {
    return {};
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true,
    },
    borderColor: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    chipClass: {
      type: String,
      required: false,
    },
  },
};
</script>
