<template>
  <button
    :class="{
      'secondary-small-button': true,
      'secondary-small-button-alert': alert,
      'secondary-small-button-inversed': inversed,
    }"
    @click="onClick"
  >
    <div class="flex gap-2 justify-between items-center">
      <span
        v-if="icon != null"
        :class="{
          'icon text-[14px]': true,
          [icon]: true,
        }"
      >
      </span>
      <span v-if="label != null" class="l3">{{ label }}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    onClick: {
      type: Function,
      required: false,
    },
    alert: {
      type: Boolean,
      required: false,
      default: false,
    },
    inversed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.secondary-small-button {
  @apply px-3 h-6 bg-transparent text-primaryV2 border border-primaryV2 rounded-full cursor-pointer flex justify-center items-center;
}

.secondary-small-button * {
  @apply text-primaryV2;
}

.secondary-small-button:hover * {
  @apply text-primary-60;
}

.secondary-small-button:hover {
  @apply text-primary-60 border-primary-60;
}

.secondary-small-button:active {
  @apply text-neutral-base border-neutral-base;
}

.secondary-small-button:disabled {
  @apply text-neutral-20 border-neutral-20 cursor-not-allowed;
}

.secondary-small-button-alert {
  @apply !border-system-alert-base;
}

.secondary-small-button-alert span {
  @apply !text-system-alert-base;
}

.secondary-small-button-alert:hover {
  @apply !border-system-alert-60;
}

.secondary-small-button-alert:hover span {
  @apply text-system-alert-60;
}

.secondary-small-button-inversed {
  @apply !border-surface-10;
}

.secondary-small-button-inversed span {
  @apply !text-surface-10;
}

.secondary-small-button-inversed:hover {
  @apply !border-surface-60;
}

.secondary-small-button-inversed:hover span {
  @apply text-surface-60;
}
</style>
