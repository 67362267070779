<template>
  <ChartBox
    title="Seans sayısı"
    subtitle="İlgili ay içerisinde yapılan toplam seans sayısı."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isVerticalMeetingsEmpty"
      :isEmpty="isVerticalMeetingsEmpty"
      :loading="loading"
    />
    <ChartColumn
      v-else
      :chartId="chartId"
      :data="generateColumnChartData(verticalMeetings, 'Seans')"
      yAxisTitle="Seans sayısı"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import type { VerticalEnum } from "~/model/vertical";
import { useStatusStore } from "~/store/status";

const props = defineProps({
  defaultVertical: {
    type: String as () => VerticalEnum,
    required: false,
  },
});

const { defaultVertical } = props;

const statusStore = useStatusStore();
const loading = computed(() => statusStore.isExportApiLoading);
const verticalMeetings = computed(
  () => statusStore.verticalExport.meetings[defaultVertical]
);

const isVerticalMeetingsEmpty = computed(
  () => !verticalMeetings.value || verticalMeetings.value.length === 0
);

const chartId = "verticalMeetings" + defaultVertical;
</script>
