<template>
  <ChartBox
    title="Seans yapan kişi sayısı"
    subtitle="İlgili ay içerisinde seans yapan toplam çalışan sayısı."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isUserWithMeetingEmpty"
      :isEmpty="isUserWithMeetingEmpty"
      :loading="loading"
    />
    <ChartColumn
      v-else
      :chartId="chartId"
      :data="generateColumnChartData(usersWithMeeting, 'Üye')"
      yAxisTitle="Kişi sayısı"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";
import type { VerticalEnum } from "~/model/vertical";

const props = defineProps({
  defaultVertical: {
    type: String as () => VerticalEnum,
    required: false,
  },
});
const { defaultVertical } = props;

const statusStore = useStatusStore();
const loading = computed(() => statusStore.isExportApiLoading);

const usersWithMeeting = computed(
  () => statusStore.verticalExport.usersWithMeeting[defaultVertical]
);
const isUserWithMeetingEmpty = computed(
  () => !usersWithMeeting.value || usersWithMeeting.value.length === 0
);

const chartId = "usersWithMeeting" + defaultVertical;
</script>
