export type LoadingExecutor<T> = (promise: () => Promise<T>) => Promise<T>;

export function useLoading<T>(): [any, LoadingExecutor<T>] {
  const loading = ref(false);
  let isMounted = true;

  onMounted(() => {
    isMounted = true;
  });

  onUnmounted(() => {
    isMounted = false;
  });

  const runWithLoading: LoadingExecutor<T> = async (
    promise: () => Promise<T>
  ): Promise<T> => {
    loading.value = true;
    let data: T;

    try {
      data = await promise();
    } finally {
      if (isMounted) {
        loading.value = false;
      }
    }

    return data;
  };

  return [loading, runWithLoading];
}
