<template>
  <ChartBox
    title="Kişi başı seans dağılımı"
    subtitle="Çalışanların yaptıkları toplam görüşme sayılarının dağılımı."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isEmpty"
      :isEmpty="isEmpty"
      :loading="loading"
    />
    <ChartColumn
      v-else
      :chartId="chartId"
      :data="generateColumnChartDataFromKeyValue(meetingCountsPerUser, 'Kişi')"
      yAxisTitle="Kişi sayısı"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import type { VerticalEnum } from "~/model/vertical";
import { useStatusStore } from "~/store/status";

const props = defineProps({
  defaultVertical: {
    type: String as () => VerticalEnum,
    required: false,
  },
});

const { defaultVertical } = props;

const statusStore = useStatusStore();
const loading = computed(() => statusStore.isExportApiLoading);
const meetingCountsPerUser = computed(
  () => statusStore.verticalExport.meetingCountsPerUser[defaultVertical]
);

const isEmpty = computed(
  () => !meetingCountsPerUser.value || meetingCountsPerUser.value.length === 0
);

const chartId = "verticalMeetingsPerUser" + defaultVertical;
</script>
