<template>
  <ChartBase
    :chartId="chartId"
    :chartOptions="lineOptions"
    :initialWidth="200"
    :initialHeight="260"
  />
</template>
<script setup lang="ts">
export type LineChartData = {
  category: string;
  series: {
    name: string;
    value: number;
  }[];
};

type Level = "zero" | "good" | "medium" | "bad";
export type ChartTick = {
  value: number;
  level: Level;
  index: number;
};

const props = defineProps({
  chartId: {
    type: String,
    required: true,
  },
  data: {
    type: Array as PropType<LineChartData[]>,
    required: true,
  },
  yAxisTitle: {
    type: String,
    required: false,
  },
  staticLineValue: {
    type: Number,
    required: false,
    default: 0,
  },
  staticLineCategory: {
    type: String,
    required: false,
  },
  chartTicks: {
    type: Array as PropType<ChartTick[]>,
    required: false,
  },
});

const {
  chartId,
  data,
  yAxisTitle,
  staticLineCategory,
  staticLineValue,
  chartTicks,
} = props;

const nuxtApp = useNuxtApp();
const highchartStyles = nuxtApp.$highchartStyles;
const { fonts, colors } = highchartStyles ?? {};

const globalColors = nuxtApp.$highcharts?.getOptions()?.colors;

const categories = data.map((item) => item.category);
const seriesNames = data[0].series.map((item) => item.name);
const seriesData = seriesNames.map((name, index) => ({
  name,
  data: data.map((item) => {
    const seriesItem = item.series.find((s) => s.name === name);
    return seriesItem ? seriesItem.value : 0;
  }),
  color: globalColors?.[index],
  dataLabels: {
    enabled: true,
  },
  marker: {
    enabled: true,
  },
}));

if (staticLineCategory) {
  const staticLineSeries = {
    name: staticLineCategory,
    data: data.map(() => staticLineValue),
    dashStyle: "3,2",
    color: colors?.primary.base,
    dataLabels: {
      enabled: false,
    },
    marker: {
      enabled: false,
    },
  };

  seriesData.push(staticLineSeries);
}

// Calculate band borders
const bandBorders = computed(() => {
  const levels = {
    good: -Infinity,
    medium: -Infinity,
    bad: -Infinity,
  };

  chartTicks.forEach((tick) => {
    if (tick.value > levels[tick.level]) {
      levels[tick.level] = tick.value;
    }
  });

  return levels;
});

const actualTickColors: Record<Level, string[]> = {
  good: [colors?.system.success.base, colors?.system.success["60"]],
  medium: [colors?.system.warning.base, colors?.system.warning["60"]],
  bad: [colors?.system.alert.base, colors?.system.alert["60"]],
  zero: [colors?.neutral["30"]],
};

const lineOptions = ref({
  chart: {
    type: "line",
    backgroundColor: colors?.surface[10],
    marginTop: 20,
  },
  title: {
    text: null,
  },
  xAxis: {
    categories: categories,
    labels: {
      style: fonts?.l3,
      rotation: categories.length > 5 ? -45 : 0,
      step: 1,
      staggerLines: 1,
    },
  },
  yAxis: {
    min: 0,
    max: 100,
    labels: {
      style: fonts?.l3,
      formatter: function (this: any) {
        const tickValue = this.value;
        const chartTick = chartTicks.find((tick) => tick.value === tickValue);
        const tickColor = actualTickColors[chartTick?.level][chartTick?.index];
        return `<span style="color: ${tickColor}">${tickValue}</span>`;
      },
      useHTML: true,
    },
    title: {
      text: yAxisTitle,
      style: fonts?.l3,
      enabled: !!yAxisTitle,
    },
    tickPositions: chartTicks.map((tick) => tick.value),
    gridLineColor: colors?.neutral["10"],
    plotBands: [
      {
        from: 0,
        to: bandBorders.value.good,
        color: colors?.system.success[10],
      },
      {
        from: bandBorders.value.good,
        to: bandBorders.value.medium,
        color: colors?.system.warning[10],
      },
      {
        from: bandBorders.value.medium,
        to: bandBorders.value.bad,
        color: colors?.system.alert[10],
      },
    ],
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: true,
        crop: false,
        overflow: "none",
        formatter: function (this: any) {
          return new Intl.NumberFormat("tr-TR").format(this.point.y);
        },
      },
      states: {
        hover: {
          enabled: true,
        },
      },
    },
  },
  tooltip: {
    useHTML: true,
    style: {
      pointerEvents: "auto",
      zIndex: 10000,
    },
    formatter: function (this: any) {
      const currentPoint = this.point;
      const previousPoint = this.series.data[this.point.index - 1];
      let comparisonText = "";
      let iconComponent = "";

      if (previousPoint) {
        const percentageChange =
          ((currentPoint.y - previousPoint.y) / previousPoint.y) * 100;

        let changeType = "nochange";

        if (percentageChange > 0) {
          changeType = "increase";
        } else if (percentageChange < 0) {
          changeType = "decrease";
        }

        const colors = {
          increase: "text-system-alert-base",
          decrease: "text-system-success-base",
          nochange: "text-system-warning-60",
        };

        const icons = {
          increase: "icon-increase",
          decrease: "icon-decrease",
          nochange: "icon-notchaged",
        };

        const comparisonTexts = {
          increase: `%${parseFloat(
            Math.abs(percentageChange).toFixed(2)
          )} artış`,
          decrease: `%${parseFloat(
            Math.abs(percentageChange).toFixed(2)
          )} düşüş`,
          nochange: "Değişim yok",
        };

        comparisonText = `<span class="${colors[changeType]}">${comparisonTexts[changeType]}</span>`;

        iconComponent = `<span class="w-[14px] h-[14px] ${colors[changeType]} ${icons[changeType]} "></span>`;
      }

      let statusText = "";
      let statusClass = "";

      if (currentPoint.y <= bandBorders.value.good) {
        statusText = "iyi";
        statusClass = "text-system-success-base";
      } else if (currentPoint.y <= bandBorders.value.medium) {
        statusText = "orta";
        statusClass = "text-system-warning-base";
      } else {
        statusText = "kötü";
        statusClass = "text-system-alert-base";
      }

      return `
        <div class="grid gap-3 b3 bg-surface-10">
          <div class="grid">
            <span class="text-neutral-40">${currentPoint.category}</span>
            <div class="flex items-center gap-1">
            <span style="background-color: ${
              this.series.color
            };" class="inline-block w-[6px] h-[6px] rounded-full "></span>
              <span>${currentPoint.series.name}:
                <span class="${statusClass}">
                ${currentPoint.y} (${statusText})
                </span>
              </span>
            </div>
          </div>
          ${
            comparisonText
              ? `
                <div class="grid">
                  <span class="text-neutral-40">Geçen aya göre</span>
                  <div class="flex gap-1 items-center">
                    ${iconComponent}
                    ${comparisonText}
                  </div>
                </div>
                `
              : ""
          }
        </div>
      `;
    },
  },
  series: seriesData,
});
</script>
