<template>
  <ChartBoxTriple
    id="checkupsContainer"
    :titles="[
      'Ortalama stres seviyesi',
      'Ortalama depresyon seviyesi',
      'Ortalama anksiyete seviyesi',
    ]"
    :subtitles="[
      'Dünya Sağlık Örgütü onaylı DASS-21 ölçeğine göre hesaplanmış stres seviyesi.',
      'Dünya Sağlık Örgütü onaylı PHQ-9 ölçeğine göre hesaplanmış depresyon seviyesi.',
      'Dünya Sağlık Örgütü onaylı GAD-7 ölçeğine göre hesaplanmış anksiyete seviyesi.',
    ]"
  >
    <template #first>
      <ChartLoading
        v-if="loading || isStressEmpty"
        :isEmpty="isStressEmpty"
        :loading="loading"
      />
      <ChartLineWithBands
        v-else
        chartId="stressBands"
        :data="generateColumnChartData(checkupResults.stress, 'Stres seviyesi')"
        :chartTicks="stressChartTicks"
      />
    </template>
    <template #second>
      <ChartLoading
        v-if="loading || isDepressionEmpty"
        :isEmpty="isDepressionEmpty"
        :loading="loading"
      />
      <ChartLineWithBands
        v-else
        chartId="depressionBands"
        :data="
          generateColumnChartData(
            checkupResults.depression,
            'Depresyon seviyesi'
          )
        "
        :chartTicks="depressionChartTicks"
      />
    </template>
    <template #third>
      <ChartLoading
        v-if="loading || isAnxietyEmpty"
        :isEmpty="isAnxietyEmpty"
        :loading="loading"
      />
      <ChartLineWithBands
        v-else
        chartId="anxietyBands"
        :data="
          generateColumnChartData(checkupResults.anxiety, 'Anksiyete seviyesi')
        "
        :chartTicks="anxietyChartTicks"
      />
    </template>
  </ChartBoxTriple>
</template>
<script setup lang="ts">
import type { ChartTick } from "~/components/chart/lineWithBands/index.vue";
import { useStatusStore } from "~/store/status";

const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const checkupResults = computed(() => statusStore.checkupResults);
const isStressEmpty = computed(
  () =>
    !checkupResults.value?.stress || checkupResults.value?.stress?.length === 0
);
const isDepressionEmpty = computed(
  () =>
    !checkupResults.value?.depression ||
    checkupResults.value?.depression?.length === 0
);
const isAnxietyEmpty = computed(
  () =>
    !checkupResults.value?.anxiety ||
    checkupResults.value?.anxiety?.length === 0
);

const anxietyChartTicks = computed(() => {
  const gad7Coefficent = 100 / 21;

  const chartTicks: ChartTick[] = [
    {
      value: 0,
      level: "zero",
      index: 0,
    },
    {
      value: Math.round(4 * gad7Coefficent),
      level: "good",
      index: 0,
    },
    {
      value: Math.round(9 * gad7Coefficent),
      level: "good",
      index: 1,
    },
    {
      value: Math.round(14 * gad7Coefficent),
      level: "medium",
      index: 0,
    },
    {
      value: Math.round(21 * gad7Coefficent),
      level: "bad",
      index: 1,
    },
  ];

  return chartTicks;
});

const depressionChartTicks = computed(() => {
  const phq9Coefficent = 100 / 27;

  const chartTicks: ChartTick[] = [
    {
      value: 0,
      level: "zero",
      index: 0,
    },
    {
      value: Math.round(4 * phq9Coefficent),
      level: "good",
      index: 0,
    },
    {
      value: Math.round(9 * phq9Coefficent),
      level: "good",
      index: 1,
    },
    {
      value: Math.round(14 * phq9Coefficent),
      level: "medium",
      index: 0,
    },
    {
      value: Math.round(19 * phq9Coefficent),
      level: "bad",
      index: 0,
    },
    {
      value: Math.round(27 * phq9Coefficent),
      level: "bad",
      index: 1,
    },
  ];

  return chartTicks;
});

const stressChartTicks = computed(() => {
  const dass21Coefficent = 100 / 42;

  const chartTicks: ChartTick[] = [
    {
      value: 0,
      level: "zero",
      index: 0,
    },
    {
      value: Math.round(15 * dass21Coefficent),
      level: "good",
      index: 0,
    },
    {
      value: Math.round(19 * dass21Coefficent),
      level: "medium",
      index: 0,
    },
    {
      value: Math.round(26 * dass21Coefficent),
      level: "medium",
      index: 1,
    },
    {
      value: Math.round(34 * dass21Coefficent),
      level: "bad",
      index: 0,
    },
    {
      value: Math.round(42 * dass21Coefficent),
      level: "bad",
      index: 1,
    },
  ];

  return chartTicks;
});

const [loading, runWithLoading] = useLoading();
const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadCheckupResults(filter.value.sponsorId, filter.value.range)
  );
};

onBeforeMount(fetchData);

watch(
  () => [filter.value.sponsorId, filter.value.range],
  async ([newSponsorId, newRange], [oldSponsorId, oldRange]) => {
    if (newSponsorId !== oldSponsorId || newRange !== oldRange) {
      await fetchData();
    }
  }
);
</script>
