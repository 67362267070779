<template>
  <div class="grid gap-6 grid-cols-3 h-[380px]">
    <ChartBox
      id="companyScopeContainer"
      title="Şirket kapsamı"
      subtitle="Heltia'ya erişimi açık toplam çalışan sayısı."
      bottomDescriptionSub="*Tüm zamanları kapsar"
    >
      <ChartLoading
        v-if="loading || isCompanyScopeEmpty"
        :isEmpty="isCompanyScopeEmpty"
        :loading="loading"
      />
      <ChartSolidgauge
        v-else
        chartId="companyScope"
        :value="userSummary?.livesCovered"
        :total="userSummary?.livesCovered"
        label="Şirket kapsamı"
        valueKind="Kişi"
      />
    </ChartBox>

    <ChartBox
      id="heltiaMembersContainer"
      title="Heltia üyeleri"
      subtitle="Heltia platformunda kayıt oluşturmuş toplam üye sayısı."
      bottomDescriptionSub="*Tüm zamanları kapsar"
      :bottomDescriptionMain="`50+ şirketin ortalaması: %${averageMemberPercentage}`"
    >
      <ChartLoading
        v-if="loading || isHeltiaMembersEmpty"
        :isEmpty="isHeltiaMembersEmpty"
        :loading="loading"
      />
      <ChartSolidgauge
        v-else
        chartId="heltiaMembers"
        :value="userSummary?.usersCount"
        :total="userSummary?.livesCovered"
        label="Heltia üyeleri"
        valueKind="Kişi"
      />
    </ChartBox>

    <ChartBox
      id="meetingHeldMembersContainer"
      title="Görüşme yapmış üyeler"
      subtitle="Heltia platformunda en az 1 uzman görüşmesi yapmış üye sayısı."
      bottomDescriptionSub="*Tüm zamanları kapsar"
      :bottomDescriptionMain="`50+ şirketin ortalaması: %${averageAtLeastOneMeetingPercentage}`"
    >
      <ChartLoading
        v-if="loading || isMeetingHeldMembersEmpty"
        :loading="loading"
        :isEmpty="isMeetingHeldMembersEmpty"
      />
      <ChartSolidgauge
        v-else
        chartId="meetingHeldMembers"
        :value="userSummary?.usersWithMeetingsCount"
        :total="userSummary?.usersCount"
        label="Görüşme yapmış üyeler"
        valueKind="Kişi"
      />
    </ChartBox>
  </div>
</template>
<script setup lang="tsx">
import { useStatusStore } from "~/store/status";

const config = useRuntimeConfig();
const averageMemberPercentage = parseInt(config.public.averageMemberPercentage);
const averageAtLeastOneMeetingPercentage = parseInt(
  config.public.averageAtLeastOneMeetingPercentage
);

const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const userSummary = computed(() => statusStore.userSummary);

const [loading, runWithLoading] = useLoading();

const isCompanyScopeEmpty = computed(
  () => !userSummary || userSummary.value?.livesCovered === 0
);

const isHeltiaMembersEmpty = computed(
  () => !userSummary || userSummary.value?.livesCovered === 0
);

const isMeetingHeldMembersEmpty = computed(
  () => !userSummary || userSummary.value?.usersCount === 0
);

const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadUserSummary(filter.value.sponsorId)
  );
};

onBeforeMount(fetchData);

watch(
  () => filter.value.sponsorId,
  async (newSponsorId, oldSponsorId) => {
    if (newSponsorId !== oldSponsorId) {
      await fetchData();
    }
  }
);
</script>
