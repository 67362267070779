<template>
  <ChartBox
    id="averageContentConsumptionContainer"
    title="Ortalama içerik tüketimi"
    subtitle="Aktif kullanıcı başına aylık ortalama içerik tüketimi."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isEmpty"
      :isEmpty="isEmpty"
      :loading="loading"
    />
    <ChartLine
      v-else
      chartId="averageContentConsumption"
      :data="generateColumnChartData(contentConsumption, sponsor.name)"
      yAxisTitle="İçerik Adedi"
      :staticLineValue="averageContentConsumption"
      staticLineCategory="50+ şirketin ortalaması"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";
import { useUserStore } from "~/store/user";

const config = useRuntimeConfig();
const averageContentConsumption = parseInt(
  config.public.averageContentConsumption
);
const statusStore = useStatusStore();
const userStore = useUserStore();

const filter = computed(() => statusStore.filter);
const contentConsumption = computed(() => statusStore.contentConsumption);
const isEmpty = computed(
  () => !contentConsumption.value || contentConsumption.value.length === 0
);
const sponsor = computed(() =>
  userStore.getSponsorById(filter.value.sponsorId)
);

const [loading, runWithLoading] = useLoading();
const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadContentConsumption(
      filter.value.sponsorId,
      filter.value.range
    )
  );
};

onBeforeMount(fetchData);

watch(
  () => [filter.value.sponsorId, filter.value.range],
  async ([newSponsorId, newRange], [oldSponsorId, oldRange]) => {
    if (newSponsorId !== oldSponsorId || newRange !== oldRange) {
      await fetchData();
    }
  }
);
</script>
