<template>
  <AppBaseRow gap="xsmall" color="bg-surface-base" justify="start">
    <ReportsFilterChip
      v-for="option in verticalOptions"
      :key="option"
      :label="getVerticalDisplayName(option)"
      :selected="selectedVertical === option"
      :onClick="() => changeVertical(option)"
    />
  </AppBaseRow>
</template>
<script setup lang="ts">
import { getVerticalDisplayName, VerticalEnum } from "~/model/vertical";
import { useStatusStore, verticalOptions } from "~/store/status";

const statusStore = useStatusStore();
const selectedVertical = computed(() => statusStore.filter.vertical);

const changeVertical = (vertical: VerticalEnum) => {
  statusStore.updateFilterVertical(vertical);
};
</script>
